import './UsefulInformation.css';
import ornement from '../ornement.svg';
import map from '../france.png';
import { Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';

function UsefulInformation({ isOpened, setIsOpened }) {
  const [isEasterEggHostingOpened, setIsEasterEggHostingOpened] = useState(false);

  return (
      <div className="useful-information-content">
        <h2>Contacts</h2>
        <img src={ornement} className="useful-information-ornement" alt="logo"/>
        <p><strong>Thibaut Cornolti</strong> et <strong>Florine Claire Daures</strong>
        <br/>Adresse postale : 13 rue du Cheval, 67100 Strasbourg
        <br/>Adresse email : <a href="mailto:thifloc2025@gmail.com">thifloc2025@gmail.com</a></p>
        <h2>Lieux</h2>
        <img src={ornement} className="useful-information-ornement" alt="logo"/>
        <div className="useful-information-map-container">
          <img src={map} className="useful-information-map" alt="map"/>
          {/* eslint-disable-next-line */}
          <a
            className='useful-information-map-hint'
            target="_blank"
            href="https://www.google.com/maps/place/Le+Moulin+de+la+Mangue/@47.558968,6.5758671,17z/data=!3m1!4b1!4m9!3m8!1s0x479269f5c3bcf7f7:0x2a76f11ac0bf6545!5m2!4m1!1i2!8m2!3d47.558968!4d6.578442!16s%2Fg%2F1tfpt5t4?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
          />
        </div>
        <h2 onClick={() => setIsEasterEggHostingOpened(true)}>Hébergements</h2>
        <img src={ornement} className="useful-information-ornement" alt="logo"/>
        <p>
          Le lieu est doté de <strong onClick={() => setIsEasterEggHostingOpened(true)}>45 couchages</strong> en cours de répartition.
          <br/>
          Vous trouverez l'information dans le <strong>faire-part</strong> que vous receverez courant <strong>février</strong>.
          <br/><br/>
          Pour ceux ne bénéficiant pas d'un couchage,
          vous avez la possibilité de <strong>camper</strong> sur le domaine,
          d'utiliser votre <strong>van</strong> aménagé sur le parking ou de <strong onClick={() => setIsEasterEggHostingOpened(true)}>réserver</strong> un logement dans les alentours.
          <br/><br/>
          Des <strong onClick={() => setIsEasterEggHostingOpened(true)}>suggestions</strong> d'hébergement seront communiquées prochainement.
        </p>

        <Dialog
          open={isEasterEggHostingOpened}
          onClose={() => setIsEasterEggHostingOpened(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <img src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExcnZ2ZG8xMTZibmFrMXRxd3ZjeDk4NHV6ZmN3Y2s5Z2JmOXM3ejNqMyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/o9W9ILrxLrHpe/giphy.webp" alt='gif' />
        </Dialog>
      </div>
  );
}

export default UsefulInformation;
