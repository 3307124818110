import './Menu.css';
import {Button} from "@mui/material";

export const menuItems = [/*"Nous", "Jour J", "Confirmer la venue", */"Nous faire plaisir", "Informations utiles"];

function Menu({setIsOpened}) {
  return (
      <div className="menu">
          {menuItems.map((item, index) => (
                <Button key={index} variant="contained" className="menu-item" onClick={() => setIsOpened(item)}>
                    {item}
                </Button>
          ))}
      </div>
  );
}

export default Menu;
