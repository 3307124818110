import './Content.css';
import {Drawer, Button} from "@mui/material";
import {menuItems} from "./Menu";
import ConfirmYourVisit from "./MenuContent/ConfirmYourVisit";
import UsefulInformation from "./MenuContent/UsefulInformation";
import PleaseUs from "./MenuContent/PleaseUs";
import { useSwipeable } from 'react-swipeable';

function Content({ isOpened, setIsOpened }) {

    const getContent = () => {
        switch (isOpened) {
            case "Confirmer la venue":
                return <ConfirmYourVisit isOpened={isOpened} setIsOpened={setIsOpened} />;
            case "Informations utiles":
                return <UsefulInformation isOpened={isOpened} setIsOpened={setIsOpened} />;
            case "Nous faire plaisir":
                return <PleaseUs isOpened={isOpened} setIsOpened={setIsOpened} />;
            default:
                return <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac
                    ligula sed libero ultrices tempor. Nullam auctor, nunc in
                </p>;
        }
    };

    const handlePreviousClick = () => {
        const currentIndex = menuItems.indexOf(isOpened);
        const previousIndex = (currentIndex - 1 + menuItems.length) % menuItems.length;
        setIsOpened(menuItems[previousIndex]);
    };

    const handleNextClick = () => {
        const currentIndex = menuItems.indexOf(isOpened);
        const nextIndex = (currentIndex + 1) % menuItems.length;
        setIsOpened(menuItems[nextIndex]);
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleNextClick,
        onSwipedRight: handlePreviousClick,
    });

    return (
        <div className="content">
            <Drawer
                anchor="bottom"
                open={isOpened}
                onClose={() => setIsOpened(false)}
                classes={{paper: 'content-drawer'}}
            >
                <div className="content-body" {...handlers}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button className="navigation-arrow" onClick={handlePreviousClick}>&lt;</Button>
                        <h1>{isOpened}</h1>
                        <Button className="navigation-arrow" onClick={handleNextClick}>&gt;</Button>
                    </div>
                    {getContent()}
                </div>
            </Drawer>
        </div>
    );
}

export default Content;
