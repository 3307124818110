import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, CircularProgress } from '@mui/material';
import './ConfirmPopup.css';

function ConfirmPopup({ confirmPerson, onConfirm, onClose }) {
    const [isLoading, setIsLoading] = useState(false);

    const onSave = async (values) => {
        setIsLoading(true);
        try {
            const res = await fetch(`https://66e3f2e684fa527d1e5c.appwrite.global/person`, {
                method: 'POST',
                body: JSON.stringify({
                    id: confirmPerson.$id,
                    attend: true,
                    ...values,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!res.ok) {
                throw new Error(res.statusText);
            }
        } catch (e) {
            console.error(e);
            setIsLoading(false);
            throw e;
        }
        setIsLoading(false);
    }

    const onClickConfirm = async (e) => {
        e.preventDefault();
        console.log(e.target.form.checkValidity());
        if (e.target.form.checkValidity()) {
            const values = {};
            new FormData(e.target.form).forEach((value, key) => {
                if (['food_truck', 'brunch', 'accommodation'].includes(key)) {
                    values[key] = value === 'true';
                } else {
                    values[key] = value || null;
                }
            });
            try {
                await onSave(values);
                onClose();
                onConfirm();
            } catch (e) {
                console.error(e);
            }
        } else {
            e.target.form.reportValidity();
        }
    }

    return confirmPerson && (
        <Dialog open={confirmPerson !== null} onClose={onClose}>
            <DialogTitle className="confirm-popup-title">
                {confirmPerson.first_name} {confirmPerson.last_name} : Confirmer la présence
            </DialogTitle>
            <div className="confirm-popup-content">
                <form>
                    <DialogContent>

                        <FormControl fullWidth required>
                            <FormLabel>Email :</FormLabel>
                            <TextField
                                fullWidth
                                name="email"
                                type="email"
                                defaultValue={confirmPerson.email}
                                sx={{ mt: 2 }}
                                required
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 2 }} required>
                            <FormLabel>Repas du 29 juillet au soir (soirée food truck, une participation de 10€ par personne est souhaitée) :</FormLabel>
                            <RadioGroup name="food_truck" defaultValue={confirmPerson.food_truck} required>
                                <FormControlLabel value="true" control={<Radio required />} label="Oui" />
                                <FormControlLabel value="false" control={<Radio />} label="Non" />
                            </RadioGroup>
                        </FormControl>

                        {confirmPerson.child ? (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <FormLabel>Repas de mariage :</FormLabel>
                                <div style={{marginTop: 5, marginBottom: 8}}>Un menu enfant unique sera proposé.</div>
                            </FormControl>
                        ) : (
                            <FormControl fullWidth sx={{ mt: 2 }} required>
                                <FormLabel>Repas de mariage :</FormLabel>
                                <RadioGroup name="meal" defaultValue={confirmPerson.meal} required>
                                    <FormControlLabel value="meat" control={<Radio required/>} label="Filet de bœuf" />
                                    <FormControlLabel value="fish" control={<Radio />} label="Filet de sandre et crevette" />
                                    <FormControlLabel value="vegetarian" control={<Radio />} label="Steak végétal" />
                                </RadioGroup>
                            </FormControl>
                        )}

                        <FormControl fullWidth sx={{ mt: 2 }} required>
                            <FormLabel>Brunch du lendemain :</FormLabel>
                            <RadioGroup name="brunch" defaultValue={confirmPerson.brunch} required>
                                <FormControlLabel value="true" control={<Radio required />} label="Oui" />
                                <FormControlLabel value="false" control={<Radio />} label="Non" />
                            </RadioGroup>
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <FormLabel>Allergies ou restrictions alimentaires :</FormLabel>
                            <TextField
                                fullWidth
                                name="allergies"
                                multiline
                                rows={2}
                                defaultValue={confirmPerson.allergies}
                                sx={{ mt: 2 }}
                            />
                        </FormControl>

                        {confirmPerson.ask_accommodation ? (
                            <FormControl fullWidth sx={{ mt: 2 }} required>
                                <FormLabel>Hébergement sur place 29 et 30 juillet {confirmPerson.child ? '' : '(90€ par personne)'} :</FormLabel>
                                <RadioGroup name="accommodation" defaultValue={confirmPerson.accommodation} required>
                                    <FormControlLabel value="true" control={<Radio required />} label="Oui" />
                                    <FormControlLabel value="false" control={<Radio />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        ) : (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <FormLabel>Hébergement :</FormLabel>
                                <div style={{marginTop: 5, marginBottom: 8}}>Vous pouvez vous référer à la page <span className="confirm-popup-useful-information">Informations Utiles</span> pour les logements à proximité.</div>
                            </FormControl>
                        )}

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <FormLabel>Moyen de transport :</FormLabel>
                            <div>Cela nous permettra de prévoir des navettes au besoin.</div>
                            <RadioGroup name="transport" defaultValue={confirmPerson.transport}>
                                <FormControlLabel value="car" control={<Radio />} label="Voiture" />
                                <FormControlLabel value="train" control={<Radio />} label="Train" />
                                <FormControlLabel value="other" control={<Radio />} label="Autre" />
                            </RadioGroup>
                        </FormControl>

                        <div style={{marginTop: 20}}>Pour toute autre demande, n'hésitez pas à nous contacter.</div>
                    </DialogContent>
                    <DialogActions className="confirm-popup-buttons">
                        <Button
                            type="submit"
                            onClick={onClickConfirm}
                            variant="contained"
                        >
                            {isLoading ? <CircularProgress size="2em" color='inherit' /> : 'Confirmer'}
                        </Button>
                    </DialogActions>
                </form>
            </div>
        </Dialog>
    );
}

export default ConfirmPopup;
