import './ConfirmYourVisit.css';
import TextField from '@mui/material/TextField';
import {useState} from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ConfirmPopup from './ConfirmPopup';

function ConfirmYourVisit({ isOpened, setIsOpened }) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isOkLoading, setIsOkLoading] = useState(false);
    const [isFamilyLoading, setIsFamilyLoading] = useState(false);
    const [familyData, setFamilyData] = useState(null);
    const [familyError, setFamilyError] = useState(null);
    const [confirmPopupPerson, setConfirmPopupPerson] = useState(null);

    const searchFamily = async () => {
        setIsFamilyLoading(true);
        try {
            const res = await fetch(`https://66e3f2e684fa527d1e5c.appwrite.global/people?firstName=${firstName}&lastName=${lastName}`);
            setFamilyError(null);
            setFamilyData(await res.json());
        } catch (error) {
            setFamilyError(error);
            setFamilyData(null);
        }
        setIsFamilyLoading(false);
    }

    const setNoAttend = async (person) => {
        setIsFamilyLoading(true);
        try {
            const res = await fetch(`https://66e3f2e684fa527d1e5c.appwrite.global/person`, {
                method: 'POST',
            body: JSON.stringify({
                id: person.$id,
                attend: false,
            }),
            headers: {
                'Content-Type': 'application/json',
                },
            });
            if (!res.ok) {
                throw new Error(res.statusText);
            }
        } catch (error) {
            console.error(error);
            setIsFamilyLoading(false);
            return;
        }
        await searchFamily();
        setIsFamilyLoading(false);
    }

    const onClickOk = async () => {
        setIsOkLoading(true);
        await searchFamily();
        setIsOkLoading(false);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && firstName && lastName) {
            onClickOk();
        }
    };

    const columns = [
        {
            field: 'first_name',
            headerName: 'Prénom',
            flex: 1,
            headerClassName: 'confirm-your-visit-header',
            renderCell: (params) => {
                return params.row.first_name;
            }
        },
        {
            field: 'attend',
            headerName: 'Présent',
            width: 175,
            headerAlign: 'left',
            headerClassName: 'confirm-your-visit-header',
            align: 'right',
            renderCell: (params) => {
                let attend = null;
                if (params.row.attend) attend = 'yes';
                if (params.row.attend === false) attend = 'no';
                return (
                    <RadioGroup value={attend} className="confirm-your-visit-radio" sx={{ alignContent: 'end' }} row>
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Oui"
                            onClick={() => setConfirmPopupPerson(params.row)}
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="Non"
                            onClick={() => setNoAttend(params.row)}
                        />
                    </RadioGroup>
                );
            }
        },
    ];

    return (
        <div className="confirm-your-visit-content">
            Merci de confirmer votre présence au plus tard le 15 mai 2025.
            <br/>Vous recevrez un email une fois votre réponse prise en compte.
            <br/>
            <br/>
            <div className='confirm-your-visit-inputs'>
                <TextField
                    className='confirm-your-visit-input'
                    label={"Nom"}
                    onChange={(e) => setLastName(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <TextField
                    className='confirm-your-visit-input'
                    label={"Prénom"}
                    onChange={(e) => setFirstName(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <Button
                    variant="contained"
                    onClick={onClickOk}
                    disabled={!firstName || !lastName}
                >
                    {isOkLoading ? (
                        <CircularProgress size="2em" color='inherit'/>
                    ) : (
                        <FavoriteBorderIcon/>
                    )}
                </Button>
            </div>
            {familyError && <div>Error: {familyError.message}</div>}
            {familyData && <div>
                <DataGrid
                    getRowId={(row) => row.$id}
                    getRowHeight={() => 50}
                    getRowClassName={() => "confirm-your-visit-row"}
                    getCellClassName={() => "confirm-your-visit-cell"}
                    rows={familyData?.documents ?? []}
                    columns={columns}
                    hideFooter
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnResize
                    loading={isFamilyLoading}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                        noRowsOverlay: {
                            variant: 'skeleton',
                        },
                    }}
                />
            </div>
            }
            <ConfirmPopup
                confirmPerson={confirmPopupPerson}
                onConfirm={() => searchFamily()}
                onClose={() => setConfirmPopupPerson(null)}
            />
        </div>
    );
}

export default ConfirmYourVisit;
